import { mapState } from "vuex";

export const metaTitle = {
    computed: {
        ...mapState('sidebar', [
            'name'
        ]),
    },
    metaInfo() {
        return {
            title: this.createTitle()
        }
    },
    methods: {
        createTitle() {
            let title = `Внутренний портал компании "${this.name}"`;
            if (this.setTitle) {
                title = `${this.setTitle}. ${title}`;
            } else if (this.$route.meta.title) {
                title = `${this.$route.meta.title}. ${title}`;
            }
            return title;
        }
    }
}
