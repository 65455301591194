import { Node } from '@tiptap/core'
import ShortCodesMenu from '../component/shortcodes/ShortCodesMenu'
import { VueNodeViewRenderer } from '@tiptap/vue-2'

export const Menu = Node.create({
    name: 'menu',
    group: 'block',
    selectable: false,
    atom: true,

    addAttributes() {
        return {
            id: {
                default: null
            }
        }
    },

    parseHTML() {
        return [{
            tag: 'menu'
        }]
    },

    renderHTML({ HTMLAttributes }) {
        return [
            'menu',
            HTMLAttributes
        ]
    },

    schema() {
        return {
            attrs: {
                id: {
                    default: null
                }
            },
            parseDOM: [{
                tag: 'menu',
                getAttrs: dom => {
                    return {
                        id: dom.getAttribute('id')
                    }
                }
            }],
            toDOM: node => {
                return ['menu', {
                    id: node.attrs.id
                }]
            }
        }
    },

    addNodeView() {
        return VueNodeViewRenderer(ShortCodesMenu)
    }
})
