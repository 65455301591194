import { Node } from '@tiptap/core'

export const Title = Node.create({
        name: 'title',
        group: 'block',
        content: 'inline*',
        priority: 101,
        parseHTML() {
            return [{
                tag: 'h1'
            }]
        },
        renderHTML() {
            return ['h1', 0]
        }
    }
)
