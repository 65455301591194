let functions = {
    num2str(n, text_forms) {
        n = Math.abs(n) % 100;
        var n1 = n % 10;

        if (n > 10 && n < 20) {
            return text_forms[2];
        }

        if (n1 > 1 && n1 < 5) {
            return text_forms[1];
        }

        if (n1 == 1) {
            return text_forms[0];
        }

        return text_forms[2];
    },
    msToTime(duration) {
        let seconds = parseInt((duration / 1000) % 60),
            minutes = parseInt((duration / (1000 * 60)) % 60),
            hours = parseInt((duration / (1000 * 60 * 60)) % 24);

        hours = (hours < 10) ? "0" + hours : hours;
        minutes = (minutes < 10) ? "0" + minutes : minutes;
        seconds = (seconds < 10) ? "0" + seconds : seconds;

        return {hours, minutes, seconds};
    },
    /* Конвертирование file в base64*/
    toBase64: file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    })
};
export default functions;
