import { DOMParser } from 'prosemirror-model'

function elementFromString(value) {
    const element = document.createElement('div')
    element.innerHTML = value.trim()

    return element
}

function insertHTML({ state, view }, value, indent = 2) {
    const { selection } = state
    const docView = view.docView
    const docLatestElement = docView.children[docView.children.length - 1]
    const pos = (docLatestElement.dom.classList.contains('is-empty') === true && selection.anchor > docLatestElement.posBefore) ? docLatestElement.posBefore : selection.anchor
    const element = elementFromString(value)
    const slice = DOMParser.fromSchema(state.schema).parseSlice(element)
    const transaction = state.tr.insert(pos, slice.content)
    view.dispatch(transaction)
}

export default insertHTML
