<template>
    <div
        class='editor'
    >
        <bubble-menu
            :editor='editor'
            v-if="editable"
        >
            <div v-show="!editor.isActive('title') && !editor.isActive('document')">
                <!--меню редактирования ссылки-->
                <div
                    class='menububble'
                    :class="{ 'is-active': editor.isActive('link') }"
                    v-if="editor.isActive('link')"
                >
                    <button
                        type='button'
                        class='menububble__button'
                        @click='openAddLink(editor.commands.setLink)'
                    >
                        <icon
                            height='12'
                            width='12'
                            icon-color='#FFF'
                            class='menububble__button-icon'
                        >
                            <path
                                d='M4.44473 3.02238C3.03077 4.48533 3.33933 6.91313 4.959 7.98862C5.01236 8.02407 5.08336 8.01704 5.1292 7.97225C5.47018 7.63908 5.75864 7.31637 6.01122 6.90593C6.04987 6.84314 6.02583 6.76166 5.961 6.72653C5.71395 6.59269 5.46812 6.34173 5.32973 6.07663L5.32957 6.07672C5.16379 5.74662 5.10735 5.37659 5.19512 4.99443C5.19521 4.99445 5.1953 4.99448 5.1954 4.99448C5.29639 4.50521 5.82165 4.05009 6.22282 3.62917C6.22197 3.62889 6.22115 3.62858 6.22031 3.6283L7.7234 2.0942C8.3224 1.48284 9.30768 1.47779 9.9129 2.08301C10.5242 2.68199 10.5343 3.67224 9.93536 4.28358L9.02492 5.21981C8.98279 5.26314 8.96912 5.32636 8.98884 5.3835C9.19846 5.99139 9.25002 6.84853 9.10955 7.49612C9.10561 7.51425 9.12798 7.52611 9.14094 7.51286L11.0786 5.53516C12.3165 4.27179 12.306 2.21483 11.0553 0.964166C9.77896 -0.312178 7.70112 -0.301556 6.43788 0.987731L4.4525 3.01408C4.44987 3.01685 4.44738 3.01966 4.44473 3.02238Z'
                                fill='#989CAE'
                            />
                            <path
                                d='M8.06699 8.26719C8.06697 8.26726 8.06692 8.26733 8.0669 8.2674C8.06814 8.26688 8.06929 8.26639 8.07053 8.26585C8.46598 7.54274 8.54383 6.71341 8.35849 5.90494L8.35765 5.90581L8.35674 5.90541C8.18076 5.18535 7.6979 4.47033 7.0421 4.03009C6.98569 3.99222 6.89558 3.99661 6.84275 4.03933C6.51056 4.30793 6.18541 4.65235 5.97086 5.09189C5.93716 5.16089 5.96239 5.24378 6.0288 5.28233C6.27779 5.42689 6.50266 5.63853 6.65322 5.91951L6.65345 5.91934C6.77078 6.11783 6.88641 6.49445 6.81151 6.89912C6.81147 6.89912 6.8114 6.89912 6.81135 6.89912C6.74148 7.43563 6.19969 7.92776 5.76899 8.3712L5.7692 8.37141C5.44135 8.70667 4.60794 9.55629 4.27423 9.89722C3.67525 10.5086 2.685 10.5187 2.07366 9.91968C1.46232 9.32071 1.45222 8.33045 2.0512 7.71912L2.96433 6.78007C3.00572 6.73751 3.01979 6.67566 3.00138 6.61922C2.79863 5.99712 2.74308 5.1593 2.87106 4.51244C2.87462 4.49441 2.85244 4.48289 2.83957 4.49603L0.931017 6.44397C-0.319512 7.72031 -0.308913 9.79837 0.954605 11.0619C2.23088 12.3123 4.29822 12.2912 5.5487 11.0149C5.98312 10.529 7.84273 8.80724 8.06699 8.26719Z'
                                fill='#989CAE'
                            />
                        </icon>
                        Редактировать ссылку
                    </button>
                    <button
                        type='button'
                        class='menububble__button'
                        @click='editor.commands.unsetLink'
                    >
                        <icon
                            height='12'
                            width='12'
                            icon-color='#FFF'
                            class='menububble__button-icon'
                        >
                            <path
                                d='M9.96776 1.2037H7.82428V0.391312C7.82428 0.175219 7.64909 0 7.43297 0H4.56673C4.35063 0 4.17541 0.175195 4.17541 0.391312V1.2037H2.03194C1.81584 1.2037 1.64062 1.3789 1.64062 1.59502V2.7987C1.64062 3.01479 1.81582 3.19001 2.03194 3.19001H9.96771C10.1838 3.19001 10.359 3.01481 10.359 2.7987V1.59502C10.359 1.37888 10.1839 1.2037 9.96776 1.2037ZM7.04163 1.2037H4.95804V0.782602H7.04163V1.2037Z'
                                fill='#989CAE'
                            />
                            <path
                                d='M2.18408 3.97266L2.50855 11.6254C2.51741 11.8348 2.68984 12.0001 2.89949 12.0001H9.1003C9.30995 12.0001 9.48238 11.8348 9.49124 11.6254L9.81568 3.97266H2.18408ZM4.817 10.4088C4.817 10.6249 4.64181 10.8001 4.42569 10.8001C4.2096 10.8001 4.03438 10.6249 4.03438 10.4088V5.56392C4.03438 5.34783 4.20957 5.17261 4.42569 5.17261C4.64179 5.17261 4.817 5.34781 4.817 5.56392V10.4088ZM6.39114 10.4088C6.39114 10.6249 6.21594 10.8001 5.99982 10.8001C5.78371 10.8001 5.60851 10.6249 5.60851 10.4088V5.56392C5.60851 5.34783 5.78371 5.17261 5.99982 5.17261C6.21594 5.17261 6.39114 5.34781 6.39114 5.56392V10.4088ZM7.96527 10.4088C7.96527 10.6249 7.79008 10.8001 7.57396 10.8001C7.35784 10.8001 7.18264 10.6249 7.18264 10.4088V5.56392C7.18264 5.34783 7.35784 5.17261 7.57396 5.17261C7.79008 5.17261 7.96527 5.34781 7.96527 5.56392V10.4088Z'
                                fill='#989CAE'
                            />
                        </icon>
                        Удалить
                    </button>
                </div>

                <!--меню форматирования текста-->
                <div
                    v-else-if='textFormattingMenuVisible'
                    class='menububble is-active'
                >
                    <!--выделить жирным-->
                    <i
                        class='menububble__button'
                        @click='editor.commands.toggleBold'
                        :class="{ 'is-active': editor.isActive('bold') }"
                    >
                        <icon height='10' width='8' icon-color='#FFF'>
                            <path
                                d='M0.121094 10V0.197266H3.68945C4.7832 0.197266 5.62402 0.397786 6.21191 0.798828C6.7998 1.19987 7.09375 1.76497 7.09375 2.49414C7.09375 3.02279 6.91374 3.48535 6.55371 3.88184C6.19824 4.27832 5.74251 4.55404 5.18652 4.70898V4.73633C5.88379 4.82292 6.43978 5.0804 6.85449 5.50879C7.27376 5.93717 7.4834 6.45898 7.4834 7.07422C7.4834 7.97201 7.16211 8.68522 6.51953 9.21387C5.87695 9.73796 4.99967 10 3.8877 10H0.121094ZM2.3291 1.82422V4.14844H3.2998C3.75553 4.14844 4.11328 4.03906 4.37305 3.82031C4.63737 3.59701 4.76953 3.29167 4.76953 2.9043C4.76953 2.18424 4.23177 1.82422 3.15625 1.82422H2.3291ZM2.3291 5.78906V8.37305H3.52539C4.03581 8.37305 4.43457 8.25456 4.72168 8.01758C5.01335 7.7806 5.15918 7.45703 5.15918 7.04688C5.15918 6.65495 5.01562 6.34733 4.72852 6.12402C4.44596 5.90072 4.04948 5.78906 3.53906 5.78906H2.3291Z'
                            />
                        </icon>
                    </i>

                    <!--выделить курсивом-->
                    <i
                        class='menububble__button'
                        @click='editor.commands.toggleItalic'
                        :class="{ 'is-active': editor.isActive('italic') }"
                    >
                        <icon height='10' width='5' icon-color='#FFF'>
                            <path
                                d='M2.56348 10H0.334961L2.40625 0.197266H4.63477L2.56348 10Z'
                            />
                        </icon>
                    </i>

                    <!--добавить ссылку-->
                    <i
                        class='menububble__button'
                        :class="{ 'is-active': editor.isActive('link') }"
                        @click='openAddLink(editor.commands.setLink)'
                    >
                        <icon height='12' width='12' icon-color='#FFF'>
                            <path
                                d='M4.44473 3.02238C3.03077 4.48533 3.33933 6.91313 4.959 7.98862C5.01236 8.02407 5.08336 8.01704 5.1292 7.97225C5.47018 7.63908 5.75864 7.31637 6.01122 6.90593C6.04987 6.84314 6.02583 6.76166 5.961 6.72653C5.71395 6.59269 5.46812 6.34173 5.32973 6.07663L5.32957 6.07672C5.16379 5.74662 5.10735 5.37659 5.19512 4.99443C5.19521 4.99445 5.1953 4.99448 5.1954 4.99448C5.29639 4.50521 5.82165 4.05009 6.22282 3.62917C6.22197 3.62889 6.22115 3.62858 6.22031 3.6283L7.7234 2.0942C8.3224 1.48284 9.30768 1.47779 9.9129 2.08301C10.5242 2.68199 10.5343 3.67224 9.93536 4.28358L9.02492 5.21981C8.98279 5.26314 8.96912 5.32636 8.98884 5.3835C9.19846 5.99139 9.25002 6.84853 9.10955 7.49612C9.10561 7.51425 9.12798 7.52611 9.14094 7.51286L11.0786 5.53516C12.3165 4.27179 12.306 2.21483 11.0553 0.964166C9.77896 -0.312178 7.70112 -0.301556 6.43788 0.987731L4.4525 3.01408C4.44987 3.01685 4.44738 3.01966 4.44473 3.02238Z'
                                fill='white'
                            />
                            <path
                                d='M8.06699 8.26719C8.06697 8.26726 8.06692 8.26733 8.0669 8.2674C8.06814 8.26688 8.06929 8.26639 8.07053 8.26585C8.46598 7.54274 8.54383 6.71341 8.35849 5.90494L8.35765 5.90581L8.35674 5.90541C8.18076 5.18535 7.6979 4.47033 7.0421 4.03009C6.98569 3.99222 6.89558 3.99661 6.84275 4.03933C6.51056 4.30793 6.18541 4.65235 5.97086 5.09189C5.93716 5.16089 5.96239 5.24378 6.0288 5.28233C6.27779 5.42689 6.50266 5.63853 6.65322 5.91951L6.65345 5.91934C6.77078 6.11783 6.88641 6.49445 6.81151 6.89912C6.81147 6.89912 6.8114 6.89912 6.81135 6.89912C6.74148 7.43563 6.19969 7.92776 5.76899 8.3712L5.7692 8.37141C5.44135 8.70667 4.60794 9.55629 4.27423 9.89722C3.67525 10.5086 2.685 10.5187 2.07366 9.91968C1.46232 9.32071 1.45222 8.33045 2.0512 7.71912L2.96433 6.78007C3.00572 6.73751 3.01979 6.67566 3.00138 6.61922C2.79863 5.99712 2.74308 5.1593 2.87106 4.51244C2.87462 4.49441 2.85244 4.48289 2.83957 4.49603L0.931017 6.44397C-0.319512 7.72031 -0.308913 9.79837 0.954605 11.0619C2.23088 12.3123 4.29822 12.2912 5.5487 11.0149C5.98312 10.529 7.84273 8.80724 8.06699 8.26719Z'
                                fill='white'
                            />
                        </icon>
                    </i>

                    <!--маркированный список-->
                    <i
                        class='menububble__button'
                        :class="{ 'is-active': editor.isActive('bulletList') }"
                        @click='editor.chain().focus().toggleBulletList().run()'
                    >
                        <icon height='14px' width='14px' icon-color='#FFF'>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                viewBox='0 0 24 24'
                            >
                                <title>list-bullets</title>
                                <circle cx='2.5' cy='3.998' r='2.5' />
                                <path
                                    d='M8.5,5H23a1,1,0,0,0,0-2H8.5a1,1,0,0,0,0,2Z'
                                />
                                <circle cx='2.5' cy='11.998' r='2.5' />
                                <path
                                    d='M23,11H8.5a1,1,0,0,0,0,2H23a1,1,0,0,0,0-2Z'
                                />
                                <circle cx='2.5' cy='19.998' r='2.5' />
                                <path
                                    d='M23,19H8.5a1,1,0,0,0,0,2H23a1,1,0,0,0,0-2Z'
                                />
                            </svg>
                        </icon>
                    </i>

                    <!--нумерованный список-->
                    <i
                        class='menububble__button'
                        :class="{ 'is-active': editor.isActive('orderedList') }"
                        @click='editor.chain().focus().toggleOrderedList().run()'
                    >
                        <icon height='14px' width='14px' icon-color='#FFF'>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                viewBox='0 0 24 24'
                            >
                                <title>list-numbers</title>
                                <path
                                    d='M7.75,4.5h15a1,1,0,0,0,0-2h-15a1,1,0,0,0,0,2Z'
                                />
                                <path
                                    d='M22.75,11h-15a1,1,0,1,0,0,2h15a1,1,0,0,0,0-2Z'
                                />
                                <path
                                    d='M22.75,19.5h-15a1,1,0,0,0,0,2h15a1,1,0,0,0,0-2Z'
                                />
                                <path
                                    d='M2.212,17.248A2,2,0,0,0,.279,18.732a.75.75,0,1,0,1.45.386.5.5,0,1,1,.483.63.75.75,0,1,0,0,1.5.5.5,0,1,1-.482.635.75.75,0,1,0-1.445.4,2,2,0,1,0,3.589-1.648.251.251,0,0,1,0-.278,2,2,0,0,0-1.662-3.111Z'
                                />
                                <path
                                    d='M4.25,10.748a2,2,0,0,0-4,0,.75.75,0,0,0,1.5,0,.5.5,0,0,1,1,0,1.031,1.031,0,0,1-.227.645L.414,14.029A.75.75,0,0,0,1,15.248H3.5a.75.75,0,0,0,0-1.5H3.081a.249.249,0,0,1-.195-.406L3.7,12.33A2.544,2.544,0,0,0,4.25,10.748Z'
                                />
                                <path
                                    d='M4,5.248H3.75A.25.25,0,0,1,3.5,5V1.623A1.377,1.377,0,0,0,2.125.248H1.5a.75.75,0,0,0,0,1.5h.25A.25.25,0,0,1,2,2V5a.25.25,0,0,1-.25.25H1.5a.75.75,0,0,0,0,1.5H4a.75.75,0,0,0,0-1.5Z'
                                />
                            </svg>
                        </icon>
                    </i>

                    <!--выделить код-->
                    <i
                        class='menububble__button'
                        :class="{ 'is-active': editor.isActive('code') }"
                        @click='editor.commands.toggleCode'
                    >
                        <icon height='10px' width='18px'>
                            <svg
                                width='18'
                                height='10'
                                viewBox='0 0 18 10'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                            >
                                <path
                                    d='M6 1L2 5L6 9'
                                    stroke='white'
                                    stroke-width='2'
                                />
                                <path
                                    d='M12 1L16 5L12 9'
                                    stroke='white'
                                    stroke-width='2'
                                />
                            </svg>
                        </icon>
                    </i>

                    <!--цитата-->
                    <i
                        class='menububble__button'
                        :class="{ 'is-active': editor.isActive('blockquote') }"
                        @click='editor.commands.toggleBlockquote'
                    >
                        <icon height='14px' width='14px' icon-color='#FFF'>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                viewBox='0 0 24 24'
                            >
                                <title>close-quote</title>
                                <path
                                    d='M18.559,3.932a4.942,4.942,0,1,0,0,9.883,4.609,4.609,0,0,0,1.115-.141.25.25,0,0,1,.276.368,6.83,6.83,0,0,1-5.878,3.523,1.25,1.25,0,0,0,0,2.5,9.71,9.71,0,0,0,9.428-9.95V8.873A4.947,4.947,0,0,0,18.559,3.932Z'
                                />
                                <path
                                    d='M6.236,3.932a4.942,4.942,0,0,0,0,9.883,4.6,4.6,0,0,0,1.115-.141.25.25,0,0,1,.277.368A6.83,6.83,0,0,1,1.75,17.565a1.25,1.25,0,0,0,0,2.5,9.711,9.711,0,0,0,9.428-9.95V8.873A4.947,4.947,0,0,0,6.236,3.932Z'
                                />
                            </svg>
                        </icon>
                    </i>

                    <!--Выделить текст-->
                    <i
                        class='menububble__button'
                        :class="{ 'is-active': editor.isActive('highlight', { color: '#FCC142' }) }"
                        @click="editor.chain().focus().toggleHighlight({ color: '#FCC142' }).run()"
                    >
                        <icon height='14px' width='14px' icon-color='#FFF'>
                            <svg viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg'>
                                <g id='Highlighter_pen'>
                                    <title>Выделить текст</title>
                                    <path
                                        d='M451.4692,125.4041,380.5656,54.5016a13.1093,13.1093,0,0,0-17.7457-.7327L164.9985,221.3722,284.6018,340.9744l167.6-197.8225A13.11,13.11,0,0,0,451.4692,125.4041Z' />
                                    <rect height='172.7608' transform='translate(-166.0416 227.6148) rotate(-45)'
                                          width='41.0143' x='171.2274' y='227.8569' />
                                    <path
                                        d='M94.5521,363.7306,142.2413,411.42c18.5232-11.008,40.65-15.58,63.1619-17.3911l-93.46-93.46C110.1316,323.08,105.56,345.2073,94.5521,363.7306Z' />
                                    <path
                                        d='M58.6143,403.6279a6.5743,6.5743,0,0,0-1.2732,7.5013l22.3748,46.4866a6.5746,6.5746,0,0,0,10.5723,1.7965l31.4111-31.4111L77.9706,384.2726Z' />
                                </g>
                            </svg>
                        </icon>
                    </i>

                    <!--Выравнивание по левому краю-->
                    <i
                        class='menububble__button'
                        :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }"
                        @click="editor.chain().focus().setTextAlign('left').run()"
                    >
                        <icon height='14px' width='14px'>
                            <svg id='icon' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' fill='#FFF'>
                                <title>По левому краю</title>
                                <rect x='12' y='6' width='14' height='2' />
                                <rect x='12' y='12' width='10' height='2' />
                                <rect x='12' y='18' width='14' height='2' />
                                <rect x='12' y='24' width='10' height='2' />
                                <rect x='6' y='4' width='2' height='24' />
                            </svg>
                        </icon>
                    </i>

                    <!--Выравнивание по центру-->
                    <i
                        class='menububble__button'
                        :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }"
                        @click="editor.chain().focus().setTextAlign('center').run()"
                    >
                        <icon height='14px' width='14px'>
                            <svg id='icon' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' fill='#FFF'>
                                <title>По центру</title>
                                <rect x='6' y='6' width='20' height='2' />
                                <rect x='10' y='12' width='12' height='2' />
                                <rect x='6' y='18' width='20' height='2' />
                                <rect x='10' y='24' width='12' height='2' />
                            </svg>
                        </icon>
                    </i>

                    <!--Выравнивание по правому краю-->

                    <i
                        class='menububble__button'
                        :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }"
                        @click="editor.chain().focus().setTextAlign('right').run()"
                    >
                        <icon height='14px' width='14px'>
                            <svg id='icon' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' fill='#FFF'>
                                <title>По правому краю</title>
                                <rect x='6' y='6' width='14' height='2' />
                                <rect x='10' y='12' width='10' height='2' />
                                <rect x='6' y='18' width='14' height='2' />
                                <rect x='10' y='24' width='10' height='2' />
                                <rect x='24' y='4' width='2' height='24' />
                            </svg>
                        </icon>
                    </i>

                    <!--Выравнивание по ширине-->

                    <i
                        class='menububble__button'
                        :class="{ 'is-active': editor.isActive({ textAlign: 'justify' }) }"
                        @click="editor.chain().focus().setTextAlign('justify').run()"
                    >
                        <icon height='14px' width='14px'>
                            <svg id='icon' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' fill='#FFF'>
                                <title>По ширине</title>
                                <rect x='6' y='6' width='20' height='2' />
                                <rect x='6' y='12' width='20' height='2' />
                                <rect x='6' y='18' width='20' height='2' />
                                <rect x='6' y='24' width='20' height='2' />
                            </svg>
                        </icon>
                    </i>
                </div>
            </div>

            <!-- menububble для картинок(горизонтальное выравнивание) -->
            <div v-show="
                    editor.isActive('document') &&
                    editor.isActive({ type: 'image' })
                "
            >
                <div class='menububble is-active'>
                    <!--Выравнивание по левому краю-->
                    <i
                        class='menububble__button'
                        title="Выровнять картинку по левому краю"
                        :class="{ 'is-active': editor.isActive({ position: 'left' }) }"
                        @click="onClickImagePosition('left', !editor.isActive({ position: 'left' }))"
                    >
                        <icon height='14px' width='14px'>
                            <svg id='icon' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' fill='#FFF'>
                                <title>По левому краю</title>
                                <rect x='12' y='6' width='14' height='2' />
                                <rect x='12' y='12' width='10' height='2' />
                                <rect x='12' y='18' width='14' height='2' />
                                <rect x='12' y='24' width='10' height='2' />
                                <rect x='6' y='4' width='2' height='24' />
                            </svg>
                        </icon>
                    </i>

                    <!--Выравнивание по центру-->
                    <i
                        class='menububble__button'
                        title="Выровнять картинку по центру"
                        :class="{ 'is-active': editor.isActive({ position: 'center' }) }"
                        @click="onClickImagePosition('center', !editor.isActive({ position: 'center' }))"
                    >
                        <icon height='14px' width='14px'>
                            <svg id='icon' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' fill='#FFF'>
                                <title>По центру</title>
                                <rect x='6' y='6' width='20' height='2' />
                                <rect x='10' y='12' width='12' height='2' />
                                <rect x='6' y='18' width='20' height='2' />
                                <rect x='10' y='24' width='12' height='2' />
                            </svg>
                        </icon>
                    </i>

                    <!--Выравнивание по правому краю-->
                    <i
                        class='menububble__button'
                        title="Выровнять картинку по правому краю"
                        :class="{ 'is-active': editor.isActive({ position: 'right' }) }"
                        @click="onClickImagePosition('right', !editor.isActive({ position: 'right' }))"
                    >
                        <icon height='14px' width='14px'>
                            <svg id='icon' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' fill='#FFF'>
                                <title>По правому краю</title>
                                <rect x='6' y='6' width='14' height='2' />
                                <rect x='10' y='12' width='10' height='2' />
                                <rect x='6' y='18' width='14' height='2' />
                                <rect x='10' y='24' width='10' height='2' />
                                <rect x='24' y='4' width='2' height='24' />
                            </svg>
                        </icon>
                    </i>
                </div>
            </div>
        </bubble-menu>

        <floating-menu
            :editor="editor"
            v-if="editable"
        >
            <div
                class='editor__floating-menu'
                :class="{'is-active': !editor.isActive('title')}"
            >
                <!--H1-->
                <i
                    class='menubar__button'
                    :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
                    @click='editor.chain().focus().toggleHeading({ level: 1 }).run()'
                >
                    <icon height='10' width='18' iconColor='#989CAE'>
                        <path
                            d='M8.60449 10H6.38965V6.00781H2.3291V10H0.121094V0.197266H2.3291V4.10742H6.38965V0.197266H8.60449V10ZM17.0947 10H10.8945V8.29102H12.9248V2.18652L10.8398 2.6377V0.887695L15.0781 0.0332031V8.29102H17.0947V10Z'
                        />
                    </icon>
                </i>

                <!--H2-->
                <i
                    class='menubar__button'
                    :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
                    @click='editor.chain().focus().toggleHeading({ level: 2 }).run()'
                >
                    <icon height='10' width='18' iconColor='#989CAE'>
                        <path
                            d='M8.60449 10H6.38965V6.00781H2.3291V10H0.121094V0.197266H2.3291V4.10742H6.38965V0.197266H8.60449V10ZM12.9521 8.16797V8.20898H16.9307V10H10.4775V8.31836L13.2871 5.63184C13.8522 5.08952 14.2487 4.63607 14.4766 4.27148C14.709 3.90234 14.8252 3.51497 14.8252 3.10938C14.8252 2.20703 14.3398 1.75586 13.3691 1.75586C12.526 1.75586 11.7194 2.09082 10.9492 2.76074V0.860352C11.8014 0.308919 12.763 0.0332031 13.834 0.0332031C14.8366 0.0332031 15.6182 0.286133 16.1787 0.791992C16.7438 1.29329 17.0264 1.97233 17.0264 2.8291C17.0264 3.97298 16.3405 5.1556 14.9688 6.37695L12.9521 8.16797Z'
                        />
                    </icon>
                </i>

                <!--H3-->
                <i
                    class='menubar__button'
                    :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
                    @click='editor.chain().focus().toggleHeading({ level: 3 }).run()'
                >
                    <icon height='10' width='18' iconColor='#989CAE'>
                        <path
                            d='M8.60449 10H6.38965V6.00781H2.3291V10H0.121094V0.197266H2.3291V4.10742H6.38965V0.197266H8.60449V10ZM10.7373 9.67871V7.79883C11.3936 8.27734 12.1592 8.5166 13.0342 8.5166C13.5856 8.5166 14.014 8.39811 14.3193 8.16113C14.6292 7.92415 14.7842 7.59375 14.7842 7.16992C14.7842 6.73242 14.5928 6.39518 14.21 6.1582C13.8317 5.92122 13.3099 5.80273 12.6445 5.80273H11.7627V4.14844H12.5762C13.8522 4.14844 14.4902 3.72461 14.4902 2.87695C14.4902 2.07943 14.0003 1.68066 13.0205 1.68066C12.3643 1.68066 11.7262 1.89258 11.1064 2.31641V0.552734C11.7946 0.20638 12.5967 0.0332031 13.5127 0.0332031C14.5153 0.0332031 15.2946 0.258789 15.8506 0.709961C16.4111 1.16113 16.6914 1.74674 16.6914 2.4668C16.6914 3.7474 16.042 4.54948 14.7432 4.87305V4.90723C15.4359 4.99382 15.9827 5.24674 16.3838 5.66602C16.7848 6.08073 16.9854 6.59115 16.9854 7.19727C16.9854 8.11328 16.6504 8.83789 15.9805 9.37109C15.3105 9.9043 14.3854 10.1709 13.2051 10.1709C12.1934 10.1709 11.3708 10.0068 10.7373 9.67871Z'
                        />
                    </icon>
                </i>

                <!--горизонтальная линия-->

                <i
                    class='menubar__button'
                    :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }"
                    @click='addHorizontalRule()'
                >
                    <icon width='18' iconColor='#989CAE'>
                        <svg id='icon' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'>
                            <title>горизонтальная линия</title>
                            <rect x='16' y='28' width='14' height='2' />
                            <rect x='16' y='23' width='14' height='2' />
                            <path
                                d='M10.8458,30H13L8.64,20H6.36L2,30H4.1542l.8-2h5.0916ZM5.7541,26,7.5,21.6347,9.2459,26Z' />
                            <rect x='2' y='15' width='28' height='2' />
                            <rect x='16' y='7' width='14' height='2' />
                            <rect x='16' y='2' width='14' height='2' />
                            <path
                                d='M10.8458,12H13L8.64,2H6.36L2,12H4.1542l.8-2h5.0916ZM5.7541,8,7.5,3.6347,9.2459,8Z' />
                        </svg>
                    </icon>
                </i>

                <!--Вертикальный разделитель-->

                <i
                    class='menubar__button'
                    :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }"
                    @click='editor.chain().focus().setHardBreak().run()'
                >
                    <icon width='18' iconColor='#989CAE'>
                        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'>
                            <title>Вертикальный разделитель</title>
                            <rect x='2' y='18' width='4' height='2' />
                            <rect x='26' y='18' width='4' height='2' />
                            <path d='M24,22v6H8V22H6v6a2.0058,2.0058,0,0,0,2,2H24a2.0058,2.0058,0,0,0,2-2V22Z'
                                  transform='translate(0 0)' />
                            <path
                                d='M8,16V4h8v6a2.0058,2.0058,0,0,0,2,2h6v4h2V10a.9092.9092,0,0,0-.3-.7l-7-7A.9087.9087,0,0,0,18,2H8A2.0058,2.0058,0,0,0,6,4V16ZM18,4.4,23.6,10H18Z'
                                transform='translate(0 0)' />
                            <rect x='10' y='18' width='4' height='2' />
                            <rect x='18' y='18' width='4' height='2' />
                        </svg>
                    </icon>
                </i>

                <!--таблица-->
                <i
                    class='menubar__button'
                    @click='
                        () => {
                            editor.chain().focus().insertTable({
                                rowsCount: 3,
                                colsCount: 3,
                                withHeaderRow: false
                            }).run()
                            addListeners()
                            firstTableMenuStyle()
                            addTableParagraph()
                        }
                    '
                >
                    <icon height='14' width='14' iconColor='#989CAE'>
                        <path
                            fill-rule='evenodd'
                            clip-rule='evenodd'
                            d='M0 0.5C0 0.223846 0.223877 0 0.5 0H13.5C13.7761 0 14 0.223846 14 0.5V3.5C14 3.77615 13.7761 4 13.5 4H0.5C0.223877 4 0 3.77615 0 3.5V0.5ZM0 6.5C0 6.22385 0.223877 6 0.5 6H3.5C3.77612 6 4 6.22385 4 6.5V8.5C4 8.77612 3.77612 9 3.5 9H0.5C0.223877 9 0 8.77612 0 8.5V6.5ZM0.5 10C0.223877 10 0 10.2239 0 10.5V12.5C0 12.7761 0.223877 13 0.5 13H3.5C3.77612 13 4 12.7761 4 12.5V10.5C4 10.2239 3.77612 10 3.5 10H0.5ZM5 6.5C5 6.22385 5.22388 6 5.5 6H8.5C8.77612 6 9 6.22385 9 6.5V8.5C9 8.77612 8.77612 9 8.5 9H5.5C5.22388 9 5 8.77612 5 8.5V6.5ZM5.5 10C5.22388 10 5 10.2239 5 10.5V12.5C5 12.7761 5.22388 13 5.5 13H8.5C8.77612 13 9 12.7761 9 12.5V10.5C9 10.2239 8.77612 10 8.5 10H5.5ZM10 6.5C10 6.22385 10.2239 6 10.5 6H13.5C13.7761 6 14 6.22385 14 6.5V8.5C14 8.77612 13.7761 9 13.5 9H10.5C10.2239 9 10 8.77612 10 8.5V6.5ZM10.5 10C10.2239 10 10 10.2239 10 10.5V12.5C10 12.7761 10.2239 13 10.5 13H13.5C13.7761 13 14 12.7761 14 12.5V10.5C14 10.2239 13.7761 10 13.5 10H10.5Z'
                        />
                    </icon>
                </i>

                <!--маркированный список-->
                <i
                    class='menubar__button'
                    :class="{ 'is-active': editor.isActive('bulletList') }"
                    @click='editor.chain().focus().toggleBulletList().run()'
                >
                    <icon height='14' width='14' iconColor='#989CAE'>
                        <path
                            d='M4.5882 3H13.4117C13.737 3 14 2.55287 14 1.99991C14 1.44695 13.737 1 13.4117 1H4.5882C4.26292 1 4 1.44695 4 1.99991C4 2.55287 4.26292 3 4.5882 3Z'
                        />
                        <path
                            d='M13.4117 6H4.5882C4.26292 6 4 6.44699 4 7C4 7.55301 4.26292 8 4.5882 8H13.4117C13.737 8 14 7.55301 14 7C14 6.44699 13.737 6 13.4117 6Z'
                        />
                        <path
                            d='M13.4117 11H4.5882C4.26292 11 4 11.4471 4 12.0001C4 12.5531 4.26292 13 4.5882 13H13.4117C13.737 13 14 12.5531 14 12.0001C14 11.4471 13.737 11 13.4117 11Z'
                        />
                        <circle cx='1' cy='2' r='1' />
                        <circle
                            cx='1'
                            cy='7'
                            r='1'
                        />
                        <circle cx='1' cy='12' r='1' />
                    </icon>
                </i>

                <!--нумерованный список-->
                <i
                    class='menubar__button'
                    :class="{ 'is-active': editor.isActive('orderedList') }"
                    @click='editor.chain().focus().toggleOrderedList().run()'
                >
                    <icon height='14' width='14' iconColor='#989CAE'>
                        <path
                            d='M1.36957 5.17395H0.456522C0.204521 5.17395 0 5.37847 0 5.63047C0 5.88247 0.204521 6.087 0.456522 6.087H1.36957C1.4536 6.087 1.5217 6.15521 1.5217 6.23913V6.39138C1.5217 6.47531 1.4536 6.54352 1.36957 6.54352H1.06518C0.47781 6.54352 0 7.02133 0 7.6087V8.36961C0 8.62161 0.204521 8.82613 0.456522 8.82613H1.97822C2.23022 8.82613 2.43475 8.62161 2.43475 8.36961C2.43475 8.11761 2.23022 7.91309 1.97822 7.91309H0.913043V7.6087C0.913043 7.52478 0.981254 7.45656 1.06518 7.45656H1.36957C1.95694 7.45656 2.43475 6.97875 2.43475 6.39138V6.23913C2.43475 5.65176 1.95694 5.17395 1.36957 5.17395Z'
                        />
                        <path
                            d='M1.36957 10.0435H0.456522C0.204521 10.0435 0 10.248 0 10.5C0 10.752 0.204521 10.9565 0.456522 10.9565H1.36957C1.4536 10.9565 1.5217 11.0246 1.5217 11.1086V11.2608C1.5217 11.3448 1.4536 11.413 1.36957 11.413H0.760907C0.508906 11.413 0.304385 11.6175 0.304385 11.8695C0.304385 12.1215 0.508906 12.326 0.760907 12.326H1.36957C1.4536 12.326 1.5217 12.3941 1.5217 12.4782V12.6303C1.5217 12.7144 1.4536 12.7826 1.36957 12.7826H0.456522C0.204521 12.7826 0 12.9871 0 13.2391C0 13.4911 0.204521 13.6956 0.456522 13.6956H1.36957C1.95694 13.6956 2.43475 13.2178 2.43475 12.6303V12.4782C2.43475 12.2517 2.3623 12.0424 2.24126 11.8695C2.3623 11.6967 2.43475 11.4872 2.43475 11.2608V11.1086C2.43475 10.5213 1.95694 10.0435 1.36957 10.0435Z'
                        />
                        <path
                            d='M0.456522 1.21736H0.913043V3.49997C0.913043 3.75197 1.11756 3.95649 1.36957 3.95649C1.62157 3.95649 1.82609 3.75197 1.82609 3.49997V0.760842C1.82609 0.508842 1.62157 0.304321 1.36957 0.304321H0.456522C0.204521 0.304321 0 0.508842 0 0.760842C0 1.01284 0.204521 1.21736 0.456522 1.21736Z'
                        />
                        <path
                            d='M4.5882 3H13.4117C13.737 3 14 2.55287 14 1.99991C14 1.44695 13.737 1 13.4117 1H4.5882C4.26292 1 4 1.44695 4 1.99991C4 2.55287 4.26292 3 4.5882 3Z'
                        />
                        <path
                            d='M13.4117 6H4.5882C4.26292 6 4 6.44699 4 7C4 7.55301 4.26292 8 4.5882 8H13.4117C13.737 8 14 7.55301 14 7C14 6.44699 13.737 6 13.4117 6Z'
                        />
                        <path
                            d='M13.4117 11H4.5882C4.26292 11 4 11.4471 4 12.0001C4 12.5531 4.26292 13 4.5882 13H13.4117C13.737 13 14 12.5531 14 12.0001C14 11.4471 13.737 11 13.4117 11Z'
                        />
                    </icon>
                </i>

                <!--цитата-->
                <i
                    class='menubar__button'
                    :class="{ 'is-active': editor.isActive('blockquote') }"
                    @click='editor.chain().focus().toggleBlockquote().run()'
                >
                    <icon height='14px' width='14px' icon-color='#989CAE'>-->
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 24 24'
                        >
                            <title>close-quote</title>
                            <path
                                d='M18.559,3.932a4.942,4.942,0,1,0,0,9.883,4.609,4.609,0,0,0,1.115-.141.25.25,0,0,1,.276.368,6.83,6.83,0,0,1-5.878,3.523,1.25,1.25,0,0,0,0,2.5,9.71,9.71,0,0,0,9.428-9.95V8.873A4.947,4.947,0,0,0,18.559,3.932Z'
                            />
                            <path
                                d='M6.236,3.932a4.942,4.942,0,0,0,0,9.883,4.6,4.6,0,0,0,1.115-.141.25.25,0,0,1,.277.368A6.83,6.83,0,0,1,1.75,17.565a1.25,1.25,0,0,0,0,2.5,9.711,9.711,0,0,0,9.428-9.95V8.873A4.947,4.947,0,0,0,6.236,3.932Z'
                            />
                        </svg>
                    </icon>
                </i>

                <!--вставить видео-->
                <div
                    class='position-relative'
                    @click="toggleMenubarSubmenu"
                >
                    <i
                        class='menubar__button'
                        data-get-menu='toggle_get_menubar__submenu'
                    >
                        <icon height='14px' width='18px' icon-color='#989CAE'>
                            <path
                                fill-rule='evenodd'
                                clip-rule='evenodd'
                                d='M3 0C1.34315 0 0 1.34315 0 3V11C0 12.6569 1.34315 14 3 14H15C16.6569 14 18 12.6569 18 11V3C18 1.34315 16.6569 0 15 0H3ZM12.5 7.86603C13.1667 7.48113 13.1667 6.51887 12.5 6.13397L8 3.5359C7.33333 3.151 6.5 3.63212 6.5 4.40192L6.5 9.59808C6.5 10.3679 7.33333 10.849 8 10.4641L12.5 7.86603Z'
                            />
                        </icon>
                    </i>
                    <div class='menubar__submenu' v-if='getMenubarSubmenu' data-get-menu='get_menubar__submenu'>
                        <label for='id_video_download' class='menubar__submenu__item'>
                            <icon>
                                <svg width='12' height='14' viewBox='0 0 12 14' fill='none'
                                     xmlns='http://www.w3.org/2000/svg'>
                                    <rect y='12' width='12' height='2' rx='1' fill='#989CAE' />
                                    <path d='M6 10V2M6 2L4 5H8L6 2Z' stroke='#989CAE' stroke-width='2' />
                                </svg>
                            </icon>
                            <span class='menubar__submenu__text'>Загрузить с компьютера</span>
                        </label>
                        <input
                            type='file'
                            accept='video/*'
                            id='id_video_download'
                            @change='onFileChange'
                            hidden
                        />
                        <div class='menubar__submenu__item' @click='openVideoModal'>
                            <icon>
                                <svg width='12' height='12' viewBox='0 0 12 12' fill='none'
                                     xmlns='http://www.w3.org/2000/svg'>
                                    <path
                                        d='M4.44473 3.02238C3.03077 4.48533 3.33933 6.91313 4.959 7.98862C5.01236 8.02407 5.08336 8.01704 5.1292 7.97225C5.47018 7.63908 5.75864 7.31637 6.01122 6.90593C6.04987 6.84314 6.02583 6.76166 5.961 6.72653C5.71395 6.59269 5.46812 6.34173 5.32973 6.07663L5.32957 6.07672C5.16379 5.74662 5.10735 5.37659 5.19512 4.99443C5.19521 4.99445 5.1953 4.99448 5.1954 4.99448C5.29639 4.50521 5.82165 4.05009 6.22282 3.62917C6.22197 3.62889 6.22115 3.62858 6.22031 3.6283L7.7234 2.0942C8.3224 1.48284 9.30768 1.47779 9.9129 2.08301C10.5242 2.68199 10.5343 3.67224 9.93536 4.28358L9.02492 5.21981C8.98279 5.26314 8.96912 5.32636 8.98884 5.3835C9.19846 5.99139 9.25002 6.84853 9.10955 7.49612C9.10561 7.51425 9.12798 7.52611 9.14094 7.51286L11.0786 5.53516C12.3165 4.27179 12.306 2.21483 11.0553 0.964166C9.77896 -0.312178 7.70112 -0.301556 6.43788 0.987731L4.4525 3.01408C4.44987 3.01685 4.44738 3.01966 4.44473 3.02238Z'
                                        fill='#989CAE' />
                                    <path
                                        d='M8.06699 8.26719C8.06697 8.26726 8.06692 8.26733 8.0669 8.2674C8.06814 8.26688 8.06929 8.26639 8.07053 8.26585C8.46598 7.54274 8.54383 6.71341 8.35849 5.90494L8.35765 5.90581L8.35674 5.90541C8.18076 5.18535 7.6979 4.47033 7.0421 4.03009C6.98569 3.99222 6.89558 3.99661 6.84275 4.03933C6.51056 4.30793 6.18541 4.65235 5.97086 5.09189C5.93716 5.16089 5.96239 5.24378 6.0288 5.28233C6.27779 5.42689 6.50266 5.63853 6.65322 5.91951L6.65345 5.91934C6.77078 6.11783 6.88641 6.49445 6.81151 6.89912C6.81147 6.89912 6.8114 6.89912 6.81135 6.89912C6.74148 7.43563 6.19969 7.92776 5.76899 8.3712L5.7692 8.37141C5.44135 8.70667 4.60794 9.55629 4.27423 9.89722C3.67525 10.5086 2.685 10.5187 2.07366 9.91968C1.46232 9.32071 1.45222 8.33045 2.0512 7.71912L2.96433 6.78007C3.00572 6.73751 3.01979 6.67566 3.00138 6.61922C2.79863 5.99712 2.74308 5.1593 2.87106 4.51244C2.87462 4.49441 2.85244 4.48289 2.83957 4.49603L0.931017 6.44397C-0.319512 7.72031 -0.308913 9.79837 0.954605 11.0619C2.23088 12.3123 4.29822 12.2912 5.5487 11.0149C5.98312 10.529 7.84273 8.80724 8.06699 8.26719Z'
                                        fill='#989CAE' />
                                </svg>
                            </icon>
                            <span class='menubar__submenu__text'>Вставить ссылку</span>
                        </div>
                    </div>
                </div>

                <!--вставить картинку-->
                <label class='menubar__button' for='id_editor_image'>
                    <icon height='14px' width='18px'>
                        <path
                            fill-rule='evenodd'
                            clip-rule='evenodd'
                            d='M16 1.5H2C1.72388 1.5 1.5 1.72385 1.5 2V10.2188L6 7.125L11 9.41666L16.5 4.375V2C16.5 1.72385 16.2761 1.5 16 1.5ZM0 2V11.25V12C0 13.1046 0.895447 14 2 14H16C17.1046 14 18 13.1046 18 12V3V2C18 0.895416 17.1046 0 16 0H2C0.895447 0 0 0.895416 0 2ZM10.5 6C11.3284 6 12 5.32843 12 4.5C12 3.67157 11.3284 3 10.5 3C9.67157 3 9 3.67157 9 4.5C9 5.32843 9.67157 6 10.5 6Z'
                            fill='#989CAE'
                        />
                    </icon>
                </label>
                <input
                    type='file'
                    ref='image'
                    accept='image/*'
                    id='id_editor_image'
                    @change='selectFile($event)'
                    hidden
                />

                <!--вставить документ-->
                <label class='menubar__button' for='id_editor_document'>
                    <icon height='14px' width='12px' icon-color='#989CAE'>
                        <path
                            fill-rule='evenodd'
                            clip-rule='evenodd'
                            d='M0 0.5C0 0.223857 0.223858 0 0.5 0H7.5C9.98528 0 12 2.01472 12 4.5V13.9C12 14.1761 11.7761 14.4 11.5 14.4H0.5C0.223858 14.4 0 14.1761 0 13.9V0.5ZM2.4 8.1C2.4 7.60294 2.80294 7.2 3.3 7.2H8.7C9.19706 7.2 9.6 7.60294 9.6 8.1C9.6 8.59706 9.19706 9 8.7 9H3.3C2.80294 9 2.4 8.59706 2.4 8.1ZM3.3 3.6C2.80294 3.6 2.4 4.00294 2.4 4.5C2.4 4.99706 2.80294 5.4 3.3 5.4H6.3C6.79706 5.4 7.2 4.99706 7.2 4.5C7.2 4.00294 6.79706 3.6 6.3 3.6H3.3Z'
                        />
                    </icon>
                </label>
                <input
                    type='file'
                    id='id_editor_document'
                    @change='addDoc($event)'
                    hidden
                />

                <!--вставить виджет-->
                <i
                    class="menubar__button"
                    @click="addWidget(editor.commands)"
                    v-if="!noVidget"
                >
                    <icon width="14" height="14">
                        <svg
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect
                                x="0.5"
                                y="0.5"
                                width="13"
                                height="13"
                                rx="1.5"
                                stroke="#989CAE"
                            />
                            <line
                                x1="3"
                                y1="4.5"
                                x2="11"
                                y2="4.5"
                                stroke="#989CAE"
                            />
                            <line
                                x1="3"
                                y1="7"
                                x2="11"
                                y2="7"
                                stroke="#989CAE"
                            />
                            <line
                                x1="3"
                                y1="9.5"
                                x2="11"
                                y2="9.5"
                                stroke="#989CAE"
                            />
                        </svg>
                    </icon>
                </i>
            </div>
        </floating-menu>
        <div v-show="editor.isActive('table')" v-if='editable' class='editor__table-menu'>
            <div class='table-menu__item' @click='editor.commands.deleteColumn'>
                <icon icon-color='#989CAE' width='14' height='14'>
                    <svg xmlns='http://www.w3.org/2000/svg'>
                        <rect width='5' height='14' rx='1' fill='#989CAE' />
                        <circle
                            cx='7'
                            cy='7'
                            r='4.5'
                            fill='white'
                            stroke='#989CAE'
                        />
                        <path
                            d='M10 7L4 7'
                            stroke='#989CAE'
                            stroke-width='1.5'
                        />
                    </svg>
                </icon>
            </div>
            <div class='table-menu__item' @click='editor.commands.deleteRow'>
                <icon icon-color='#989CAE' width='14' height='14'>
                    <svg xmlns='http://www.w3.org/2000/svg'>
                        <g>
                            <rect width='14' height='14' fill='white' />
                            <rect
                                x='14'
                                width='5'
                                height='14'
                                rx='1'
                                transform='rotate(90 14 0)'
                                fill='#989CAE'
                            />
                            <circle
                                cx='7'
                                cy='7'
                                r='4.5'
                                fill='white'
                                stroke='#989CAE'
                            />
                            <path
                                d='M10 7L4 7'
                                stroke='#989CAE'
                                stroke-width='1.5'
                            />
                        </g>
                        <defs>
                            <clipPath id='clip0'>
                                <rect width='14' height='14' fill='white' />
                            </clipPath>
                        </defs>
                    </svg>
                </icon>
            </div>
            <div class='table-menu__item' @click='editor.commands.addColumnAfter'>
                <icon icon-color='#989CAE' width='14' height='14'>
                    <svg xmlns='http://www.w3.org/2000/svg'>
                        <rect width='14' height='14' fill='white' />
                        <rect width='5' height='14' rx='1' fill='#989CAE' />
                        <path
                            d='M10 4V10'
                            stroke='#989CAE'
                            stroke-width='1.5'
                        />
                        <path
                            d='M7 7L13 7'
                            stroke='#989CAE'
                            stroke-width='1.5'
                        />
                    </svg>
                </icon>
            </div>
            <div class='table-menu__item' @click='editor.commands.addRowAfter'>
                <icon icon-color='#989CAE' width='14' height='14'>
                    <svg xmlns='http://www.w3.org/2000/svg'>
                        <g>
                            <rect width='14' height='14' fill='white' />
                            <rect
                                x='14'
                                width='5'
                                height='14'
                                rx='1'
                                transform='rotate(90 14 0)'
                                fill='#989CAE'
                            />
                            <path
                                d='M10 10L4 10'
                                stroke='#989CAE'
                                stroke-width='1.5'
                            />
                            <path
                                d='M7 7V13'
                                stroke='#989CAE'
                                stroke-width='1.5'
                            />
                        </g>
                        <defs>
                            <clipPath id='clip0'>
                                <rect width='14' height='14' fill='white' />
                            </clipPath>
                        </defs>
                    </svg>
                </icon>
            </div>
            <div class='table-menu__item' @click='editor.commands.mergeOrSplit'>
                <icon icon-color='#989CAE' width='14' height='14'>
                    <svg fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <g>
                            <rect width='14' height='14' fill='white' />
                            <rect
                                x='13.5'
                                y='0.5'
                                width='13'
                                height='13'
                                rx='1.5'
                                transform='rotate(90 13.5 0.5)'
                                stroke='#989CAE'
                            />
                            <path d='M7 2V4' stroke='#989CAE' />
                            <path d='M7 6V8' stroke='#989CAE' />
                            <path d='M7 10V12' stroke='#989CAE' />
                        </g>
                        <defs>
                            <clipPath id='clip0'>
                                <rect width='14' height='14' fill='white' />
                            </clipPath>
                        </defs>
                    </svg>
                </icon>
            </div>
            <div class='table-menu__item' @click='editor.commands.deleteTable'>
                <icon icon-color='#989CAE' width='14' height='14'>
                    <g>
                        <path
                            d='M11.6293 1.40432H9.12857V0.456531C9.12857 0.204422 8.92418 0 8.67204 0H5.32809C5.07598 0 4.87156 0.204395 4.87156 0.456531V1.40432H2.37084C2.11873 1.40432 1.91431 1.60871 1.91431 1.86085V3.26514C1.91431 3.51725 2.1187 3.72168 2.37084 3.72168H11.6292C11.8813 3.72168 12.0858 3.51728 12.0858 3.26514V1.86085C12.0858 1.60869 11.8814 1.40432 11.6293 1.40432ZM8.21548 1.40432H5.78462V0.913035H8.21548V1.40432Z'
                            fill='#989CAE'
                        />
                        <path
                            d='M2.54834 4.63477L2.92689 13.5629C2.93722 13.8073 3.13839 14.0001 3.38298 14.0001H10.6173C10.8619 14.0001 11.063 13.8073 11.0734 13.5629L11.4519 4.63477H2.54834ZM5.62008 12.1436C5.62008 12.3957 5.41569 12.6001 5.16355 12.6001C4.91144 12.6001 4.70702 12.3957 4.70702 12.1436V6.49124C4.70702 6.23913 4.91141 6.03471 5.16355 6.03471C5.41566 6.03471 5.62008 6.23911 5.62008 6.49124V12.1436ZM7.45657 12.1436C7.45657 12.3957 7.25218 12.6001 7.00004 12.6001C6.7479 12.6001 6.54351 12.3957 6.54351 12.1436V6.49124C6.54351 6.23913 6.7479 6.03471 7.00004 6.03471C7.25218 6.03471 7.45657 6.23911 7.45657 6.49124V12.1436ZM9.29306 12.1436C9.29306 12.3957 9.08867 12.6001 8.83653 12.6001C8.58439 12.6001 8.38 12.3957 8.38 12.1436V6.49124C8.38 6.23913 8.58439 6.03471 8.83653 6.03471C9.08867 6.03471 9.29306 6.23911 9.29306 6.49124V12.1436Z'
                            fill='#989CAE'
                        />
                    </g>
                    <defs>
                        <clipPath id='clip0'>
                            <rect fill='white' />
                        </clipPath>
                    </defs>
                </icon>
            </div>
            <!--            <button @click='editor.chain().focus().toggleHeaderCell().run()'-->
            <!--                    :disabled='!editor.can().toggleHeaderCell()'>-->
            <!--                toggleHeaderCell-->
            <!--            </button>-->
            <!--            <button @click='editor.chain().focus().toggleHeaderColumn().run()'-->
            <!--                    :disabled='!editor.can().toggleHeaderColumn()'>-->
            <!--                toggleHeaderColumn-->
            <!--            </button>-->
            <!--            <button @click='editor.chain().focus().toggleHeaderRow().run()' :disabled='!editor.can().toggleHeaderRow()'>-->
            <!--                toggleHeaderRow-->
            <!--            </button>-->
            <!--            <button-->
            <!--                @click="editor.chain().focus().setCellAttribute('backgroundColor', '#FAF594').run()"-->
            <!--                :disabled="!editor.can().setCellAttribute('backgroundColor', '#ffffff')">-->
            <!--                setCellAttribute-->
            <!--            </button>-->
        </div>
        <editor-content :editor='editor'/>
        <drop-zone-preview
            v-show="isDropZone && editable"
            in-type="fullscreen"
            @customDrop="onFileDrop"
            @customDragEnd="onFileDragEnd"
            @customDragLeave="onFileDragEnd"
        ></drop-zone-preview>
    </div>
</template>

<script>
    import axios from 'axios'
    import { Editor, EditorContent, BubbleMenu, FloatingMenu } from '@tiptap/vue-2'
    import StarterKit from '@tiptap/starter-kit'
    import Document from '@tiptap/extension-document'
    import Paragraph from '@tiptap/extension-paragraph'
    import Text from '@tiptap/extension-text'
    import Highlight from '@tiptap/extension-highlight'
    import Placeholder from '@tiptap/extension-placeholder'
    import Link from '@tiptap/extension-link'
    import BulletList from '@tiptap/extension-bullet-list'
    import OrderedList from '@tiptap/extension-ordered-list'
    import ListItem from '@tiptap/extension-list-item'
    import Code from '@tiptap/extension-code'
    import Blockquote from '@tiptap/extension-blockquote'
    import Table from '@tiptap/extension-table'
    import TableRow from '@tiptap/extension-table-row'
    import TableHeader from '@tiptap/extension-table-header'
    import TableCell from '@tiptap/extension-table-cell'
    import CustomFloatingMenu from '@tiptap/extension-floating-menu'
    import { Image as TipTapImage } from '@tiptap/extension-image'
    import { CustomTableCell } from './addons/CustomTableCell'
    import { Title } from './addons/Title'
    import { Iframe } from './addons/Iframe'
    import { CustomDoc } from './addons/Doc'
    import { Document as CustomDocument } from './addons/Document'
    import { Menu as CustomMenu } from './addons/Menu'
    import TextAlign from '@tiptap/extension-text-align'
    import Icon from '@/components/icon/Icon'
    import session from '@/api/session'
    import insertHtml from './insertHtml'
    import functions from '@/assets/js/functions'
    import { CustomVideo } from './addons/CustomVideo.js'
    import {mapState, mapMutations} from "vuex";
    import DropZonePreview from '@/components/drop-zone-preview/DropZonePreview'


    export default {
        components: {
            EditorContent,
            BubbleMenu,
            FloatingMenu,
            CustomFloatingMenu,
            Icon,
            DropZonePreview
        },
        props: {
            editable: {
                type: Boolean,
                default: true
            },
            data: {
                required: false,
                default: ''
            },
            form: {
                type: Object,
                required: false,
                default: () => {}
            },
            topPlaceholder: {
                type: String,
                required: true
            },
            topTitle: {
                required: false,
                default() {
                    return this.$props.topPlaceholder
                }
            },
            bottomPlaceholder: {
                type: String,
                required: true
            },
            noVidget: {
                type: Boolean,
                default: false
            },
        },

        data() {
            return {
                getMenubarSubmenu: false,
                editor: new Editor({
                    extensions: [
                        StarterKit,
                        CustomVideo,
                        Document,
                        Paragraph,
                        Text,
                        TextAlign.configure({
                            types: ['heading', 'paragraph']
                        }),
                        Title,
                        Placeholder.configure({
                            placeholder: ({ node }) => {
                                if (node.type.name === 'title') {
                                    return this.topPlaceholder
                                }
                                return this.bottomPlaceholder
                            },
                            showOnlyCurrent: false,
                            showOnlyWhenEditable: false
                        }),
                        Highlight.configure({ multicolor: true }),
                        Link,
                        BulletList,
                        OrderedList,
                        ListItem,
                        Code,
                        Blockquote,
                        Table.configure({
                            resizable: true
                        }),
                        TableRow,
                        TableHeader,
                        TableCell,
                        CustomTableCell,
                        Iframe,
                        TipTapImage.configure({
                            inline: true,
                            allowBase64: true,
                        }),
                        CustomDoc,
                        CustomDocument,
                        CustomMenu,
                    ],
                    editable: this.editable,
                    content: this.data,
                    autofocus: this.editable ? (this.topTitle.length + 1) : false,
                    onUpdate: this.updateInput,
                    onPaste: this.onPaste,
                    onBlur: this.onBlur,
                    editorProps: {
                        handlePaste: this.pasteFromClipboard
                    }
                }),
                maxImageResolution: 4096,
                maxImageMbSize: 2,
                isDropZone: false,
                isMaterialSave: false
            }
        },

        computed: {
            ...mapState('default_data', ['newCourseId']),
            textFormattingMenuVisible() {
                return !this.editor.isActive('customVideo')
                    && !this.editor.isActive('iframe')
                    && !this.editor.isActive('image')
            }
        },

        methods: {
            ...mapMutations('default_data', ['setNewCourseId']),
            openAddLink(command) {
                const view = this.editor.view;
                const selection = view.state.selection;
                const range = selection.ranges[0];
                const clientRects = view.coordsAtPos(range.$from.pos);

                const windowWidth = document.documentElement.clientWidth
                const windowHeight = document.documentElement.clientHeight
                const pivotX = clientRects.left / windowWidth
                const pivotY = (clientRects.top - 66) / windowHeight

                const state = this.editor.state
                const { from, to } = state.selection
                let marks = []
                state.doc.nodesBetween(from, to, node => {
                    marks = [...marks, ...node.marks]
                })
                const mark = marks.find(
                    markItem => markItem.type.name === 'link'
                )
                const AddLinkModal = () =>
                    import(`@/components/editor/modals/AddLinkModal`)
                this.$modal.show(
                    AddLinkModal,
                    {
                        command,
                        link: mark?.attrs?.href || ''
                    },
                    {
                        name: 'add-link-modal',
                        scrollable: true,
                        height: 'auto',
                        adaptive: true,
                        maxWidth: 420,
                        width: '100%',
                        pivotY,
                        pivotX
                    }
                )
            },
            addTableMenuStyle(e) {
                const tableMenu = document.querySelector('.editor__table-menu')
                tableMenu.style.top = `${e.target.closest('.tableWrapper')
                    .offsetTop - 47}px`
            },
            firstTableMenuStyle() {
                this.$nextTick(() => {
                    const tableMenu = document.querySelector(
                        '.editor__table-menu'
                    )
                    tableMenu.style.top = `${document.querySelector(
                        '.tableWrapper'
                    ).offsetTop - 47}px`
                })
            },
            addTableParagraph() {
                const p = document.createElement('p')
                document
                    .querySelector('.ProseMirror')
                    .insertBefore(
                        p,
                        document.querySelectorAll('.tableWrapper').nextSibling
                    )
            },
            addListeners() {
                this.$nextTick(() => {
                    const twr = document.querySelectorAll('.tableWrapper')
                    twr.forEach(el => {
                        el.addEventListener('click', e => {
                            this.addTableMenuStyle(e)
                        })
                    })
                })
            },
            toggleMenubarSubmenu(event) {
                const toggle = event.target.closest('[data-get-menu=\'toggle_get_menubar__submenu\']')
                if (toggle) {
                    this.getMenubarSubmenu = !this.getMenubarSubmenu
                    return
                }
                if (event.target.closest('[data-get-menu=\'get_menubar__submenu\']')) {
                    this.getMenubarSubmenu = true
                    return
                }
                this.getMenubarSubmenu = false
            },
            onPaste() {
                this.$nextTick(() => {
                    this.editor.setContent(
                        this.editor.getHTML().replace(/&nbsp;/g, '')
                    )
                })
            },
            onBlur() {
                this.getMenubarSubmenu = false
            },

            openVideoModal() {
                const AddVideoModal = () =>
                    import(`@/components/editor/modals/AddVideoModal`)
                this.$modal.show(
                    AddVideoModal,
                    {
                        onAdd: this.addVideoEmbed
                    },
                    {
                        name: 'add-video-modal',
                        scrollable: true,
                        height: 'auto',
                        adaptive: true,
                        maxWidth: 740,
                        width: '100%'
                    }
                )
            },
            onFileChange(e) {
                console.log(e)
                this.addVideo(e)
                this.getMenubarSubmenu = false;
            },
            addVideoEmbed(embed) {
                insertHtml(this.editor, embed, 1)
                this.getMenubarSubmenu = false;
            },
            addWidget(command) {
                insertHtml(
                    this.editor,
                    `<menu id='${this.$route.params.material_id || ''}'></menu>`
                )
            },
            otmenaToken() {
                this.$swal.close()
                this.ourRequest.cancel()
            },
            addVideo (e) {
                this.handleVideo(e, this.$route.params)
            },
            handleVideo: async function(e, params) {
                this.ourRequest = axios.CancelToken.source()
                const file = e.target.files[0]
                if (!file.type.startsWith('video')) {
                    return this.$swal({
                        title: `Выберите видео файл`,
                        icon: 'error',
                        showConfirmButton: false,
                        showCancelButton: false,
                        allowOutsideClick: false,
                        defeat: true,
                        timer: 1500
                    })
                }

                const config = {
                    onUploadProgress: function(progressEvent) {
                        const percentElement = document.getElementById(
                            'percent'
                        )
                        const percentUpload = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        )
                        percentElement.innerHTML = `${percentUpload}%`
                    }
                }

                if (params.material_id) {
                    this.addVideoInMaterial(e, file, config)
                } else {
                    this.addVideoInCourse(e, file, config, params.course_id)
                }
            },
            updateInput(ev) {
                const html = this.editor.getHTML();
                if (!html.startsWith('<h1>')) {
                    this.editor.commands.setContent(`<h1></h1>${html}`)
                }
                const description = html.replace(/<h1[^>]*>\s*(.*?)\s*<\/h1>/i, '')
                if (description.length === 0) {
                    this.editor.commands.setContent(`${html}<p></p>`)
                }

                this.$emit('input', {
                    html: this.editor.getHTML(),
                    json: this.editor.getJSON()
                })
            },
            async selectFile(e) {
                // if (e.target.files[0]) {
                //     const file = e.target.files[0]
                //     this.editor.chain().focus().setImage({ src: await functions.toBase64(file) }).run()
                //     insertHtml(this.editor, `<p></p>`, 0)
                // }
                // e.target.value = ''
                // Сделал логику для картинок как для документов
                const file = e.target.files[0]
                if (this.$route.params.material_id) {
                    this.handleDocumentInMaterial(e, file, this.$route.params.material_id)
                } else {
                    this.handleDoc(e, file, this.$route.params.course_id)
                }
            },
            addHorizontalRule() {
                this.editor.chain().focus().setHorizontalRule(`<hr>`, 0).run()
            },
            async addDoc(e) {
                const file = e.target.files[0]
                if (this.$route.params.material_id) {
                    this.handleDocumentInMaterial(e, file, this.$route.params.material_id)
                } else {
                    this.handleDoc(e, file, this.$route.params.course_id)
                }
            },
            async handleDoc(e, file, courseId) {
                let attachment = {}
                const config = {
                    onUploadProgress: function(progressEvent) {
                        const percentElement = document.getElementById(
                            'percent'
                        )
                        const percentUpload = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        )
                        percentElement.innerHTML = `${percentUpload}%`
                    }
                }
                if (!courseId && !this.newCourseId) {
                    await this.$emit('needSubmitBeforeAttach', () => this.handleDoc(e, file, this.newCourseId))
                    return
                }

                this.addDocSwal()

                if (courseId && !this.$route.params.module_id) {
                    await session
                        .post(`/api/v1/course/${courseId ? courseId : this.newCourseId}/attachment/`,
                            {
                                file_name: file.name,
                                file_size: file.size
                            }
                        )
                        .then(response => (attachment = response.data))
                    await axios
                        .put(attachment.upload_link, file, { ...config })
                        .then(response => {
                            this.$swal.close()
                        })
                        .catch(error => {
                            console.error(error)
                            this.$swal.close()
                            return this.$swal({
                                title: `При загрузке произошла ошибка. Попробуйте ещё раз`,
                                icon: 'error',
                                showConfirmButton: false,
                                showCancelButton: false,
                                allowOutsideClick: false,
                                defeat: true,
                                timer: 1500
                            })
                        })
                } else {
                    this.addDocInLesson(e, file, config)
                    return
                }

                this.insertHtmlAddDocument(attachment)

                this.setNewCourseId(null)
                this.$refs.image.value = null
            },
            async handleDocumentInMaterial(e, file, materialId) {
                let attachment = {}
                const config = {
                    onUploadProgress: function(progressEvent) {
                        const percentElement = document.getElementById(
                            'percent'
                        )
                        const percentUpload = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        )
                        percentElement.innerHTML = `${percentUpload}%`
                    }
                }

                this.addDocSwal()
                const file_name = file.name.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '');

                if (
                    materialId == 'new-material' &&
                    this.isMaterialSave == false
                ) {
                    const form = JSON.parse(JSON.stringify(this.form));

                    form.text = this.data.html
                    form.title = this.topTitle

                    await session.post(`/api/v1/material/`, form)
                        .then(response => {
                            materialId = response.data.id
                            this.isMaterialSave = true
                        })
                        .catch((error) => {
                            console.error(error)
                        })
                }

                await session
                    .post(
                        `/api/v1/material/${materialId}/attachment/`, {
                            file_name: file_name,
                            file_size: file.size
                        }
                    )
                    .then(response => (attachment = response.data))
                await axios
                    .put(attachment.upload_link, file, { ...config })
                    .then(response => {
                        this.$swal.close()
                    })

                this.insertHtmlAddDocument(attachment)
            },
            async addVideoInMaterial(e, file, config) {
                let attachment = {}

                this.addVideoSwal()

                await session.post(
                    `/api/v1/material/${this.$route.params.material_id}/attachment/`,
                    {
                        file_name: e.target.files[0].name,
                        file_size: e.target.files[0].size
                    },
                    {
                        cancelToken: this.ourRequest.token
                    }
                ).then(response => (attachment = response.data))

                try {
                    await axios.put(attachment.upload_link, file, { cancelToken: this.ourRequest.token, ...config })
                    this.$swal.close()
                } catch (e) {
                    console.log('error', e)
                }

                e.target.value = ''


                this.insertHtmlAddVideo(attachment)
            },
            async addVideoInCourse(e, file, config, courseId) {
                let attachment = {}

                this.addVideoSwal()

                if (!courseId && !this.newCourseId) {
                    await this.$emit('needSubmitBeforeAttach', () => this.addVideoInCourse(e, file, config, this.newCourseId))
                    return
                }

                await session.post(
                    `/api/v1/course/${courseId ? courseId : this.newCourseId}/attachment/`, {
                        file_name: e.target.files[0].name,
                        file_size: e.target.files[0].size
                    }, {
                        cancelToken: this.ourRequest.token
                    }
                ).then(response => (attachment = response.data))

                try {
                    await axios.put(attachment.upload_link, file, { cancelToken: this.ourRequest.token, ...config })
                    this.$swal.close()
                } catch (e) {
                    console.log('error', e)
                }

                e.target.value = ''

                this.insertHtmlAddVideo(attachment)
            },
            async addDocInLesson(e, file, config) {
                let attachment = {}
                // if (this.$route.params.lesson_id) {
                //     console.log('params.lesson_id', this.$route.params.lesson_id)
                //     const link = `api/v1/course-lesson/${this.$route.params.lesson_id}/attachment/`
                //     await session
                //         .post(link,
                //             {
                //                 file_name: file.name,
                //                 file_size: file.size
                //             }
                //         )
                //         .then(response => (attachment = response.data))
                //     await axios
                //         .put(attachment.upload_link, file, { ...config })
                //         .then(() => {
                //             this.$swal.close()
                //         })
                //
                //     this.insertHtmlAddDocument(attachment)
                // } else {
                //     console.log('this.$route.params.course_id', this.$route.params.course_id)
                //     const link = `api/v1/course/${this.$route.params.course_id}/attachment/`
                //     await session
                //         .post(link,
                //             {
                //                 file_name: file.name,
                //                 file_size: file.size
                //             }
                //         )
                //         .then(response => (attachment = response.data))
                //     await axios
                //         .put(attachment.upload_link, file, { ...config })
                //         .then(() => {
                //             this.$swal.close()
                //         })
                //     this.insertHtmlAddDocument(attachment)
                // }
                const link = `api/v1/course/${this.$route.params.course_id}/attachment/`
                await session.post(link, {
                        file_name: file.name,
                        file_size: file.size
                    }
                ).then(response => (attachment = response.data))

                await axios.put(attachment.upload_link, file, { ...config }).then(() => {
                    this.$swal.close()
                })

                this.insertHtmlAddDocument(attachment)
            },
            insertHtmlAddVideo(attachment) {
                insertHtml(
                    this.editor,
                    `<vue-component name='video' src='${attachment.link}' id='${attachment.id}'></vue-component>`,
                    0
                )
            },
            insertHtmlAddDocument(attachment) {
                insertHtml(
                    this.editor,
                    `<document
                        id='${attachment.id}'
                    ></document>`
                )
            },
            addVideoSwal() {
                this.$swal({
                    title: `Загрузка Видео... <span id='percent'></span>`,
                    icon: 'info',
                    showConfirmButton: false,
                    showCancelButton: false,
                    allowOutsideClick: false,
                    html: `<i class='fa fa-2x fa-cog fa-spin'></i><br/><br/></br>`,
                    defeat: true
                })
            },
            addDocSwal() {
                this.$swal({
                    title: `Загрузка документа... <span id='percent'></span>`,
                    icon: 'info',
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    html: `<i class='fa fa-2x fa-cog fa-spin'></i>`
                })
            },
            pasteFromClipboard(view, event) {
                const items = Array.from(event.clipboardData?.items || []);
                for (const item of items) {
                    if (item.type.indexOf("image") === 0) {
                        let realFile = item.getAsFile();
                        let filesize = ((realFile.size / 1024) / 1024).toFixed(4);
                        let newEditorThis = this;

                        if (filesize < this.maxImageMbSize) {
                            let _URL = window.URL || window.webkitURL;
                            let img = new Image(); /* global Image */
                            img.src = _URL.createObjectURL(realFile);
                            img.onload = function () {
                                if (this.width > newEditorThis.maxImageResolution || this.height > newEditorThis.maxImageResolution) {
                                    newEditorThis.$swal({
                                        icon: 'error',
                                        title: 'Ошибка',
                                        text: `Ваше изображение должно быть меньше ${newEditorThis.maxImageResolution} пикселей по ширине или высоте`,
                                        showConfirmButton: true,
                                        showCancelButton: false,
                                        allowOutsideClick: false
                                    })
                                } else {
                                    if (newEditorThis.$route.params.material_id) {
                                        newEditorThis.handleDocumentInMaterial(event, realFile, newEditorThis.$route.params.material_id)
                                    } else {
                                        newEditorThis.handleDoc(event, realFile, newEditorThis.$route.params.course_id)
                                    }
                                }
                            };
                        } else {
                            newEditorThis.$swal({
                                icon: 'error',
                                title: 'Ошибка',
                                text: `Изображение должно быть меньше ${newEditorThis.maxImageMbSize} Мб`,
                                showConfirmButton: true,
                                showCancelButton: false,
                                allowOutsideClick: false
                            })
                        }
                        return true;
                    }
                }
                return false;
            },

            onFileDrop(event) {
                window.focus()
                this.isDropZone = false

                let editCursorPos = parseInt(this.editor.view.state.selection.$anchor.pos);

                if (editCursorPos < 2) {
                    this.editor.chain().focus('end', {scrollIntoView: false}).run()
                } else {
                    this.editor.chain().focus(editCursorPos).run()
                }

                if (event.dataTransfer.items) {
                    [...event.dataTransfer.items].forEach((variable, index, array) => {
                        if (variable.kind === "file") {
                            const file = variable.getAsFile()

                            if (this.$route.params.material_id) {
                                this.handleDocumentInMaterial(event, file, this.$route.params.material_id)
                            } else {
                                this.handleDoc(event, file, this.$route.params.course_id)
                            }
                        }
                    })
                }

                event.preventDefault()
            },

            onFileDragOver(event) {
                // Условие является костыльным решением: событие так же срабатывает, когда переносят выделенный текст
                if (window.getSelection().isCollapsed === true) {
                    this.isDropZone = true
                    event.preventDefault()
                }
            },

            onFileDragEnd(event) {
                this.isDropZone = false
                event.preventDefault()
            },

            onClickImagePosition(type, status) {
                const position = (status) ? type : null;

                this.editor.state.selection.node.attrs.position = position;
                this.editor.chain().updateAttributes('document', { 'position': position }).run();
                this.editor.commands.setTextSelection({ from: 1, to: 10 });
            }
        },

        mounted() {
            document.addEventListener('dragstart', this.toggleMenubarSubmenu)

            if (this.editable) {
                document.body.addEventListener('dragover', this.onFileDragOver, false);
            }

            this.updateInput()
            this.addListeners()
        },

        beforeUnmount() {
            this.editor.destroy()
        },

        destroyed() {
            document.removeEventListener('click', this.toggleMenubarSubmenu)
            this.setNewCourseId(null)
        }
    }
</script>

<style lang='scss'>
    @import 'assets/sass/main';
    @import '#sass/v-style';

    $color-black: #373a45;
    $color-white: #ffffff;
    $color-grey: #5f6475;
    .swal_vide_custom_cancel {
        border: 1px solid #5f6475;
        border-radius: 6px;
        color: #fff;
        background-color: #5f6475;
        padding: 10px;
        cursor: pointer;
    }

    .editor *.is-empty:nth-child(1)::before,
    .editor *.is-empty:nth-child(2)::before {
        content: attr(data-placeholder);
        float: left;
        color: #aaa;
        pointer-events: none;
        height: 0;
    }

    .ProseMirror-focused *.is-empty:nth-child(2)::before {
        opacity: 0;
    }

    .content {
        &__editor {
            height: 100%;
            padding-bottom: 20px;

            div[contenteditable='true'] {
                min-height: 250px;
            }

            div[contenteditable='true']:focus > *[data-placeholder='Описание материала'].is-empty {
                &::before {
                    content: '';
                }
            }
        }
    }

    .editor {
        position: relative;

        &__content {
            height: 100%;
            padding-bottom: 20px;

            div[contenteditable='true'] {
                min-height: 250px;
            }

            div[contenteditable='true']:focus > p.is-empty {
                &::before {
                    content: '';
                }
            }
        }

        &__floating-menu {
            display: flex;
            align-items: center;
            position: absolute;
            top: -5px;
            left: 0;
            z-index: 1;
            //margin-top: 3px;
            visibility: hidden;
            opacity: 0;
            //transition: opacity 0.2s, visibility 0.2s;
            //background: rgba($color-white, 0.8);
            background: $color-white;

            &.is-active {
                opacity: 1;
                visibility: visible;
            }
        }

        img {
            max-width: 100%;
        }

        table {
            border-collapse: collapse;
            table-layout: fixed;
            width: 100%;
            margin: 0;
            overflow: hidden;

            td, th {
                min-width: 1em;
                border: 2px solid #ddd;
                padding: 3px 5px;
                vertical-align: top;
                box-sizing: border-box;
                position: relative;

                > * {
                    margin-bottom: 0;
                }
            }

            th {
                font-weight: bold;
                text-align: left;
            }

            .selectedCell:after {
                z-index: 2;
                position: absolute;
                content: "";
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background: rgba(200, 200, 255, 0.4);
                pointer-events: none;
            }

            .column-resize-handle {
                position: absolute;
                right: -2px;
                top: 0;
                bottom: 0;
                width: 4px;
                z-index: 20;
                background-color: #adf;
                pointer-events: none;
            }
        }

        .tableWrapper {
            margin: 1em 0;
            overflow-x: auto;
        }

        .resize-cursor {
            cursor: ew-resize;
            cursor: col-resize;
        }

        p,
        div.document__card {
            margin-bottom: 10px;
        }

        h1 {
            margin: 30px 0;
        }

        h2 {
            margin: 25px 0;
        }

        h3 {
            margin: 20px 0;
        }

        ul {
            list-style: disc;
            padding-left: 20px;
        }

        ol {
            padding-left: 20px;
        }

        a {
            color: #2173d3 !important;

            &:hover {
                color: #5690d3 !important;
            }
        }

        strong {
            color: inherit;
        }

        blockquote {
            border-left: 6px solid #fbc04f;
            color: #000;
            font-weight: 600;
            padding-left: 28px;

            p {
                margin: 0;
            }
        }

        p code {
            padding: 0.2rem 0.4rem;
            border-radius: 5px;
            font-size: 0.8rem;
            font-weight: bold;
            background: rgba($color-black, 0.1);
            color: rgba($color-black, 0.8);
        }

        .ProseMirror {
            & > :first-child {
                margin-top: 0;
            }

            //p.is-editor-empty:first-child::before {
            //    content: attr(data-placeholder);
            //    float: left;
            //    color: #ced4da;
            //    pointer-events: none;
            //    height: 1px;
            //}

            table {
                border-collapse: collapse;
                table-layout: fixed;
                width: 100%;
                margin: 10px 0;
                overflow: hidden;

                td,
                th {
                    min-width: 1em;
                    border: 2px solid #ced4da;
                    padding: 3px 5px;
                    vertical-align: top;
                    box-sizing: border-box;
                    position: relative;

                    > * {
                        margin-bottom: 0;
                    }
                }

                th {
                    font-weight: bold;
                    text-align: left;
                    background-color: #f1f3f5;
                }

                .selectedCell:after {
                    z-index: 2;
                    position: absolute;
                    content: "";
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    background: rgba(200, 200, 255, 0.4);
                    pointer-events: none;
                }

                .column-resize-handle {
                    position: absolute;
                    right: -2px;
                    top: 0;
                    bottom: -2px;
                    width: 4px;
                    background-color: #adf;
                    pointer-events: none;
                }

                p {
                    margin: 0;
                }
            }
        }

        .menubar {
            &__submenu {
                background: #FFFFFF;
                box-shadow: 0 4px 24px rgba(0, 0, 0, 0.15);
                border-radius: 4px;
                top: 25px;
                position: absolute;
                left: 20px;

                &__text {
                    font-size: 15px;
                    line-height: 20px;
                    color: #000000;
                    font-weight: 400;
                }

                &__item {
                    min-width: 230px;
                    width: 100%;
                    padding: 16px;
                    cursor: pointer;
                }
            }

            &__button {
                display: flex;
                cursor: pointer;
                margin: 0 0 0 20px;

                &:hover {
                    background-color: transparent;

                    g {
                        transition: all $ease;
                        fill: $gold;
                    }
                }

                &.is-active {
                    background-color: transparent;

                    g {
                        transition: all $ease;
                        fill: $gold;
                    }
                }
            }
        }

        .menububble {
            //position: absolute;
            display: flex;
            z-index: 9999;
            background: $color-black;
            border-radius: 5px;
            padding: 5px;
            //margin-bottom: 0.5rem;
            //transform: translateX(-50%);
            visibility: hidden;
            opacity: 0;
            transition: opacity 0.2s, visibility 0.2s;

            &.is-active {
                opacity: 1;
                visibility: visible;
            }

            &__button {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                background: transparent;
                border: 0;
                color: $color-white;
                padding: 8px;
                margin-right: 0.2rem;
                border-radius: 3px;
                cursor: pointer;
                max-height: 30px;
                font-size: 14px;
                line-height: 28px;

                &-icon {
                    margin-right: 6px;
                }

                &:last-child {
                    margin-right: 0;
                }

                &:hover {
                    background-color: rgba($color-white, 0.1);
                }

                &.is-active {
                    background-color: rgba($color-white, 0.2);
                }
            }

            &__form {
                display: flex;
                align-items: center;
            }

            &__input {
                font: inherit;
                border: none;
                background: transparent;
                color: $color-white;
            }
        }

        .iframe-wrapper {
            iframe {
                width: 100%;
                height: 500px;
                border: 0;
            }
        }

        .iframe {
            &__embed {
                width: 100%;
                height: 500px;
                border: 0;
            }

            &__input {
                display: block;
                width: 100%;
                font: inherit;
                border: 0;
                border-radius: 5px;
                background-color: rgba($color-black, 0.1);
                padding: 0.3rem 0.5rem;
            }
        }
    }
</style>
