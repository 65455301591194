import { mergeAttributes, Node } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-2'
import CustomVideoComponent from '../component/CustomVideo'

export const CustomVideo = Node.create({
    name: 'customVideo',
    group: 'block',
    atom: true,

    addAttributes() {
        return {
            src: {
                default: ''
            },
            id: {
                default: null
            }
        }
    },

    parseHTML() {
        return [
            {
                tag: 'vue-component'
            }
        ]
    },

    renderHTML({ HTMLAttributes }) {
        return ['vue-component', mergeAttributes(HTMLAttributes)]
    },

    addNodeView() {
        return VueNodeViewRenderer(CustomVideoComponent)
    }
})
