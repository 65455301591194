<template>
    <div class="content content-view">
        <slot/>
    </div>
</template>

<script>
export default {
    name: 'ContentView'
}
</script>

<style scoped>

</style>
