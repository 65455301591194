<template>
    <div>
        <div class="shortcodes-menu-list-item__wrapper" v-if="item.title">
            <button
                class="shortcodes-menu-list-item__button"
                @click="loadChildren(item.id)"
                v-if="item.have_children"
            >
                <icon
                    width="8"
                    height="8"
                    class="shortcodes-menu-list-item__button__icon"
                    :class="{
                        'shortcodes-menu-list-item__button__icon--open': isOpen
                    }"
                >
                    <svg
                        width="8"
                        height="8"
                        viewBox="0 0 8 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M8 4L0 0V8L8 4Z" fill="currentColor" />
                    </svg>
                </icon>
            </button>
            <router-link
                class="shortcodes-menu-list-item__link"
                :to="`/knowledge/article/${item.id}?from=KnowledgeBase`"
            >
                <span class="shortcodes-menu-list-item__link-img">
                    <icon width="12" height="14"  v-if="item.required">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M0 0.5C0 0.223857 0.223858 0 0.5 0H11.1667C11.4428 0 11.6667 0.223858 11.6667 0.5V13.5C11.6667 13.7761 11.4428 14 11.1667 14H0.5C0.223858 14 0 13.7761 0 13.5V0.5ZM5.83333 8.16667C5.189 8.16667 4.66667 7.64433 4.66667 7V3.5C4.66667 2.85567 5.189 2.33333 5.83333 2.33333C6.47766 2.33333 7 2.85567 7 3.5V7C7 7.64433 6.47767 8.16667 5.83333 8.16667ZM5.83333 9.625C5.189 9.625 4.66667 10.1473 4.66667 10.7917C4.66667 11.436 5.189 11.9583 5.83333 11.9583C6.47767 11.9583 7 11.436 7 10.7917C7 10.1473 6.47767 9.625 5.83333 9.625Z"
                            fill="#FBC04F"
                        />
                    </icon>
                    <emoji
                        class="shortcodes-menu-list-item__link-emodji"
                        :emoji="item.emoji"
                        set="apple"
                        :size="16"
                        v-else-if="item.emoji"
                    />
                    <img
                        v-else
                        class="shortcodes-menu__img"
                        src="../../../../assets/img/favicons/material.svg"
                    />
                </span>
                <span class="shortcodes-menu__title">{{ item.title }}</span>
            </router-link>
        </div>

        <transition name="slide">
            <div
                v-if="isOpen || item.children.length"
                class="shortcodes-menu__item"
                style="transformOrigin: top;
                transition: transform .4s ease-in-out;"
            >
                <ul
                    class="shortcodes-menu-list"
                    :key="indexChildren"
                    v-for="(children, indexChildren) in item.children"
                >
                    <li class="shortcodes-menu-list-item" :key="item.id">
                        <ShortCodesMenuItem
                            :item="children"
                            :index="indexChildren"
                        ></ShortCodesMenuItem>
                    </li>
                </ul>
            </div>
        </transition>
    </div>
</template>

<script>
import session from '@/api/session'
import { Emoji } from 'emoji-mart-vue'
import Icon from '@/components/icon/Icon'

export default {
  name: 'ShortCodesMenuItem',
  data() {
    return {
      apiMenu: '/api/v1/knowledge-base/',
      isOpen: false
    }
  },
  components: {
    Emoji,
    Icon
  },
  props: {
    item: {
      type: Object,
      default() {
        return {}
      }
    },
    index: {
      type: Number,
      default: 0
    }
  },
  watch: {
    'item.children': {
      handler: function(val) {
        if (val.length) {
          this.isOpen = true
        }
      },
      deep: true
    }
  },
  mounted() {
    if (this.item.children.length) {
      this.isOpen = true
    }
  },
  methods: {
    async loadChildren(id) {
      if (this.isOpen) {
        this.item.children = []
        this.isOpen = false
      } else {
        try {
          const request = await session.get(
            this.apiMenu + `${id}/children/`,
            {}
          )
          this.item.children = request.data
        } catch (error) {
          console.error(error)
        }
        this.isOpen = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.slide-enter,
.slide-leave-to {
    transform: scaleY(0);
}
.shortcodes-menu {
    background: #ffffff;
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 32px;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    margin: 10px 0;
    position: relative;

    &__img {
        width: 10px;
        min-width: 10px;
        height: 12px;
    }

    &-delete {
        position: absolute;
        top: -20px;
        right: 30px;

        &__button {
            border-radius: 100%;
            background-color: #fff;
            font-size: 5px;
            width: 40px;
            height: 40px;
            border: 1px solid #e6e6e6;
            box-sizing: border-box;
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            &:hover {
                color: #e32b2b;
                border: 1px solid #e32b2b;
            }
        }
    }

    &__item {
        padding-top: 14px;
    }

    &-list {
        list-style: none;
        padding-left: 0;

        &-item {
            padding: 6px 0;
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
            /* identical to box height, or 140% */
            color: #000000;
            transition: all 2s ease-in-out;

            &__wrapper {
                display: flex;
                align-items: center;
            }

            &__button {
                position: relative;
                background-color: #fff;
                border: 0;
                cursor: pointer;
                color: #9d9d9e;
                &:hover {
                    color: #000;
                }

                &__icon {
                    &--open {
                        transform: rotate(90deg);
                    }
                }

                svg {
                    transition: transform 200ms ease, color 100ms ease;
                    position: absolute;
                    left: -20px;
                    top: -4px;
                }
            }

            &__link {
                align-items: center;
                display: flex;
                color: #000 !important;

                &:hover {
                    text-decoration: underline;
                    color: #000 !important;
                }

                &-img {
                    margin-right: 12px;
                    display: flex;
                    align-items: center;
                }

                &-emodji {
                    display: flex !important;
                }
            }
        }
    }

    &-icon {
        /* @include icon($rating-like-icon, 15px)*/
    }
}

.no-content {
    display: flex;
    min-height: 300px;
    background: #ffffff;
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 32px;
    align-items: center;
    justify-content: center;
    text-align: center;
}
</style>
