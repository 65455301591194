<template>
    <svg xmlns="http://www.w3.org/2000/svg"
         :width="width"
         :height="height"
         :viewBox="`0 0 ${width} ${height}`"
         role="presentation"
    >
        <g :fill="iconColor">
            <slot />
        </g>
    </svg>
</template>

<script>
export default {
    name: "Icon",
    props: {
        width: {
            type: [Number, String],
            default: 18
        },
        height: {
            type: [Number, String],
            default: 18
        },
        iconColor: {
            type: String,
            default: 'currentColor'
        }
    }
}
</script>

<style scoped>

</style>
