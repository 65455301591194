// import { Doc } from 'tiptap'
import { Node } from '@tiptap/vue-2'

export const CustomDoc = Node.create({
    content: 'title block+'
})
// export default class CustomDoc extends Doc {
//
//     get schema() {
//         return {
//             content: 'title block+',
//         }
//     }
//
// }
