export  function ValidateEditorVideo(jsonHtml){
    let wrapper= document.createElement('div');
    wrapper.innerHTML = jsonHtml
    const  errorsValidate = wrapper.querySelectorAll('p iframe')
    if (errorsValidate.length !== 0){
        const  p = wrapper.querySelectorAll('p')
        p.forEach(e =>{
            const iframe = e.querySelector('iframe');
            if (iframe){
                wrapper.appendChild(iframe)
                wrapper.removeChild(e)
            }
        })
        return  wrapper.innerHTML
    }
    return jsonHtml
}
