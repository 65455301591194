import TableCell from '@tiptap/extension-table-cell'

export const CustomTableCell = TableCell.extend({
    addAttributes() {
        return {
            ...this.parent?.(),

            backgroundColor: {
                default: null,
                parseHTML: element => element.getAttribute('data-background-color'),
                renderHTML: attributes => {
                    return {
                        'data-background-color': attributes.backgroundColor,
                        width: attributes.colwidth,
                        style: `background-color: ${attributes.backgroundColor || 'transparent'}`
                    }
                }
            }
        }
    }
})
