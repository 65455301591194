<template>
    <div
        :class="['drop-zone', `drop-zone--${inType}`]"
        draggable="true"
        @drop="onDrop"
        @dragover="onDragOver"
        @dragend="onDragEnd"
        @dragleave="onDragLeave"
    >
        <div class="drop-zone__main">
            <div class="drop-zone__content">
                <img class="drop-zone__pic" src="@/assets/img/drag-and-drop.svg" loading="lazy">
                <p class="drop-zone__intro">Перетащите файлы сюда</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'DropZonePreview',
        props: {
            inType: {
                type: String,
                default: 'default'
            }
        },
        methods: {
            onDrop(event) {
                this.$emit('customDrop', event)
            },

            onDragOver(event) {
                this.$emit('customDragOver', event)
            },

            onDragEnd(event) {
                this.$emit('customDragEnd', event)
            },

            onDragLeave(event) {
                this.$emit('customDragLeave', event)
            }
        }
    }
</script>

<style lang="scss">
    @import '@/sass/_variables.sass';

    .drop-zone {
        $p: &;
        padding: 20px;
        display: flex;
        position: relative;
        &__main {
            display: flex;
            align-items: center;
            justify-content: center;

            flex-grow: 1;
            border-radius: 10px;
            border-width: 2px;
            border-color: $gold;
            border-style: dashed;
        }

        &__content {
            pointer-events: none;

            font-weight: bold;
            text-align: center;
            width: 100%;
            max-width: 380px;
            padding: 35px 20px;
            border-radius: 10px;
            background-color: #fff;
            box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.07);
        }

        &__pic {
            height: 128px;
            width: 128px;
            object-fit: contain;
        }

        &__intro {
            font-size: 21px !important;
            margin: 15px 0px 0px !important;
        }

        &--fullscreen {
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            position: fixed;

            z-index: 99999;
            background-color: $bg-overlay;
            .drop-zone {
                &__main {
                    border-width: 5px;
                }
            }
        }
    }
</style>
