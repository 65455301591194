<template>
    <node-view-wrapper ref='videoRef'>
        <div class='custom'>
            <button
                v-if='editor.view.editable'
                type='button'
                class='document__card-del-btn document__card-btn--delete'
                @click='deleteVideo()'
            >
                <icon height='16' width='16' icon-color='#989CAE'>
                    <g clip-path='url(#clip0)'>
                        <path
                            d='M13.2908 1.60494H10.4329V0.52175C10.4329 0.233625 10.1993 0 9.91111 0H6.08946C5.80133 0 5.56771 0.233594 5.56771 0.52175V1.60494H2.70974C2.42161 1.60494 2.18799 1.83853 2.18799 2.12669V3.73159C2.18799 4.01972 2.42158 4.25334 2.70974 4.25334H13.2908C13.5789 4.25334 13.8125 4.01975 13.8125 3.73159V2.12669C13.8126 1.8385 13.579 1.60494 13.2908 1.60494ZM9.38933 1.60494H6.61121V1.04347H9.38933V1.60494Z'
                            fill='currentColor'
                        />
                        <path
                            d='M2.91211 5.29688L3.34473 15.5005C3.35655 15.7798 3.58645 16.0001 3.86598 16.0001H12.1337C12.4133 16.0001 12.6432 15.7798 12.655 15.5005L13.0876 5.29688H2.91211ZM6.42267 13.8784C6.42267 14.1665 6.18908 14.4001 5.90092 14.4001C5.6128 14.4001 5.37917 14.1665 5.37917 13.8784V7.41856C5.37917 7.13044 5.61277 6.89681 5.90092 6.89681C6.18905 6.89681 6.42267 7.13041 6.42267 7.41856V13.8784ZM8.52152 13.8784C8.52152 14.1665 8.28792 14.4001 7.99977 14.4001C7.71161 14.4001 7.47802 14.1665 7.47802 13.8784V7.41856C7.47802 7.13044 7.71161 6.89681 7.99977 6.89681C8.28792 6.89681 8.52152 7.13041 8.52152 7.41856V13.8784ZM10.6204 13.8784C10.6204 14.1665 10.3868 14.4001 10.0986 14.4001C9.81045 14.4001 9.57686 14.1665 9.57686 13.8784V7.41856C9.57686 7.13044 9.81045 6.89681 10.0986 6.89681C10.3868 6.89681 10.6204 7.13041 10.6204 7.41856V13.8784Z'
                            fill='currentColor'
                        />
                    </g>
                    <defs>
                        <clipPath id='clip0'>
                            <rect width='16' height='16' fill='white' />
                        </clipPath>
                    </defs>
                </icon>
            </button>
            <video :src='node.attrs.src' controls class='custom__video'></video>
        </div>
    </node-view-wrapper>
</template>

<script>
    import { nodeViewProps, NodeViewWrapper } from '@tiptap/vue-2'
    import Icon from '@/components/icon/Icon'
    import session from '../../../api/session'

    export default {
        components: {
            NodeViewWrapper,
            Icon
        },
        props: nodeViewProps,
        methods: {
            deleteVideo: function() {
                this.$swal({
                    customClass: {
                        confirmButton: 'btn btn-lg btn-alt-success m-5',
                        cancelButton: 'btn btn-lg btn-alt-danger m-5'
                    },
                    title: 'Удалить видео?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Да, удалить!',
                    cancelButtonText: 'Отменить'
                }).then(
                    value => {
                        if (value.value) {
                            const swal = this.$swal({
                                title: 'Удаление...',
                                icon: 'info',
                                showConfirmButton: false,
                                allowOutsideClick: false,
                                html: '<i class="fa fa-2x fa-cog fa-spin"></i>'
                            })
                            // выполнение
                            session
                                .delete(
                                    `/api/v1/material/${this.$route.params.material_id}/attachment/${this.node.attrs.id}/`
                                )
                                .then(response => {
                                    swal.close()
                                    this.$refs.videoRef.$el.remove()
                                })
                                .catch(err => {
                                    if (err.response.status === 404) {
                                        this.$refs.videoRef.$el.remove()
                                        swal.close()
                                    } else {
                                        this.$swal({
                                            title: 'Ошибка!',
                                            text:
                                                'При обработке запроса произошла ошибка на сервере',
                                            icon: 'error',
                                            showConfirmButton: false,
                                            timer: 1400
                                        })
                                    }
                                })
                        }
                    }
                )
            }
        }
    }
</script>

<style scoped lang='scss'>
    .custom {
        position: relative;
        margin: 20px 0;

        &__video {
            width: 100%;
            height: 500px;
        }
    }

    .document__card-del-btn {
        position: absolute;
        top: -20px;
        right: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        width: 20px;
        height: 20px;
        border: 1px solid #dbdbdb;
        border-radius: 50%;
        color: #cccccc;
        background: #fff;
        transition: border-color 0.15s ease-in-out, color 0.15s ease-in-out;
        cursor: pointer;

        &:hover {
            border-color: red;
            color: red;
        }
    }
</style>
